<script>
import api from '@/api/index.js'
import tool from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog,
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: true, title: '新增' },
        remove: { show: true, title: '删除' },
        edit: { show: true, title: '编辑' },
        view: { show: true, title: '详情' },
        valid: { show: false },
        updateStatus: { show: false }
      },
      statusType: [
        '首页',
        '行业案例',
        '产品中心-碳探专业版',
        '产品中心-碳速算',
        '产品中心-碳查查',
        '碳资讯-政策标准',
        '碳资讯-产业深度',
        '碳资讯-市场洞察',
        '碳资讯-奕碳动态',
        '关于奕碳-企业介绍',
        '关于奕碳-加入我们',
        '关于奕碳-招募代理商',
      ],
      showList: [
        // {
        //   copName: 'el-input',
        //   placeholder: '图片名称',
        //   key: 'name',
        //   name: '',
        //   attrs: ['placeholder']
        // }
      ],
      tableTile: [
        // {
        //   key: 'name',
        //   columnLabel: '名字',
        //   prop: 'name',
        //   width: ''
        // },
        {
          key: '1',
          columnLabel: '所属模块',
          prop: 'type',
          width: '',
          formatter: (row, column, cellValue, index) => cellValue ? this.statusType[cellValue - 1] : ''
        },
        {
          key: '1',
          columnLabel: '图片',
          prop: 'imgUrl',
          width: '',
          formatter: (row, column, cellValue, index) => {
            const h = this.$createElement
            return h('el-image', {
              attrs: {
                previewSrcList: [cellValue],
                src: cellValue
              },
              style: {
                width: '100px',
                height: '50px'
              }
            })
          }
        },
        {
          key: 'imgUrl',
          columnLabel: '地址',
          prop: 'imgUrl',
          width: '',
        }
      ]
    }
  },
  created () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline,
          queryParam: null
        }
      }
      api.banner.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },
    // 新增
    add () {
      this.$refs.myDialog.add()
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.banner.remove({ id: id })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const res = await api.banner.findById({ id })
      this.$refs.myDialog.edit(res.data || {})
    },
    // 详情
    async view (id) {
      const res = await api.banner.findById({ id })
      this.$refs.myDialog.view(res.data || {})
    },
  }
}
</script>
